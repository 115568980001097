@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

.card {
	flex: 1 0 calc(50% - (10px));
	position: relative;
	cursor: pointer;

	@include tablet() {
		flex: 1 0 calc(25% - (60px / 4));
	}

	&.disableScroll {
		position: relative;
	}

	&:not([class*="disableScroll"]) {
		&:first-child {
			margin-left: calc((50% + 10px) * -1);

			@include tablet() {
				margin-left: calc((25%) * -1);
			}
		}
	}

	p {
		margin-top: 5px;
		margin-bottom: 0;
	}

	& .video {
		width: 100%;
		padding-top: 177%;
		position: relative;

		& .videoLength {
			position: absolute;
			bottom: 10px;
			right: 10px;
			color: $white;
			font-size: 1.4rem;
			line-height: 1.6rem;
			font-weight: 400;
		}

		& video {
			position: absolute;
			height: 100%;
			width: 100%;
			top: 0;
			border-radius: 4px;
		}
	}

	&:hover {
		.playButton {
			transform: scale(1.1) translate(-50%, -50%);
		}
	}
}

.playButton {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	cursor: pointer;
	opacity: 1;
	transition:
		opacity 0.4s,
		transform 0.2s;
	position: absolute;
}
