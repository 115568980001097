@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

@keyframes slideUp {
	from {
		bottom: -100%;
		opacity: 0;
	}

	to {
		bottom: 0;
		opacity: 1;
	}
}

@keyframes slideDown {
	from {
		bottom: 0;
		opacity: 1;
	}

	to {
		bottom: -100%;
		opacity: 0;
	}
}

.background {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.4);
	z-index: $z-index-above-all-backdrop;
	content: " ";
}

.closeHandle {
	width: 100%;
	height: 50px;
	position: relative;

	&::after {
		position: absolute;
		top: 50%;
		left: 50%;
		height: 4px;
		width: 50px;
		content: " ";
		border-radius: 30px;
		background: $grey;
		transform: translate(-50%, -50%);
	}

	@include tablet() {
		display: none;
	}
}

.slapLabel {
	margin-bottom: 20px;
}

.wrapper {
	padding: 30px 15px 20px;
	position: fixed;
	bottom: -100%;
	left: 0;
	right: 0;
	visibility: hidden;
	transition: visibility 0.4s ease-in-out;
	animation: slideDown 0.4s ease-in-out forwards;
	background: $white;
	z-index: $z-index-above-all;
	max-height: 90%;
	overflow: auto;

	// Hide the 3 radio buttons that show on limited time product.
	opacity: 0;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	@include tablet() {
		transition: unset;
		animation: unset;
	}

	&.stickyButtons {
		padding-bottom: 0;
	}

	&.darkTheme {
		background: $deep-green;

		& .darkThemeIcon {
			color: $white;
		}

		& p,
		& h1,
		& h2,
		& h3,
		& h4 {
			color: $white;
		}
	}

	&.noTopPad {
		// Used to remove padding on mobile devices when close handle is visible
		padding-top: 0;

		@include tablet() {
			padding-top: 30px;
		}
	}

	&.noPad {
		padding: 0;

		& .closeHandle {
			margin-top: 10px;
		}
	}

	&.shown {
		bottom: 0;
		visibility: visible;
		max-width: 100vw;
		opacity: 1;
		pointer-events: all;
		animation: slideUp 0.4s ease-in-out forwards;

		@include tablet() {
			animation: none;
			bottom: auto;
			top: 50%;
			left: 50%;
			max-width: 600px;
			transform: translate(-50%, -50%);
			max-height: 90vh;
			display: flex;
			flex-direction: column;

			&.wider {
				width: 800px;
				max-width: 90vw;
			}

			&.widerTablet {
				width: 700px;
				max-width: 80vw;
			}
		}

		@include tabletLarge() {
			&.widerTablet {
				max-width: 600px;
				max-height: 90vh;
			}
		}

		&.limitHeight {
			max-height: 525px;
		}
	}
}

.content {
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
	overflow-y: visible;
	text-wrap: wrap;

	&.allowOverflow {
		overflow-y: visible;
		height: auto;
		flex-grow: 0;
		min-height: 0;
	}

	& form {
		width: 100%;
	}

	&.noButtons {
		margin-bottom: 0;
	}
}

.buttons {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
}

.icon {
	margin-bottom: 20px;
	align-items: center;
}

.headerImg {
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
}
