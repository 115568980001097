@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

.recipeBox {
	background-color: $white;
	position: relative;
	padding: 10px;
	text-align: left;
	height: 100%;
	transition:
		background-color 0.4s ease-in-out,
		left 0.2s ease-out;
	display: flex;
	flex-direction: column;

	& .categoryTag {
		position: absolute;
		top: 0;
		right: 6px;
		background: $light-green;
		color: $white;
		font-size: 1.4rem;
		padding: 5px;
		z-index: 5;
		border-radius: 3px;
		transform: translateY(-50%);
		text-transform: capitalize;
	}

	&.border {
		border: $deep-green 1px solid;
	}

	&.unsuitable {
		opacity: 0.5;
	}

	&.selected {
		background-color: $deep-green;

		h2 {
			color: $white;
		}

		h4 {
			color: $white;
		}

		.content {
			p {
				color: $light-grey;
			}
		}

		.detailsButton {
			color: $white;
		}

		.addButton {
			color: $white;
			border-color: $white;
			background: $deep-green;
			display: block;
		}
	}

	.image {
		height: 140px;

		> div {
			height: 100%;
		}

		img {
			object-fit: cover;
		}
	}

	.unsuitableReason {
		background-color: $red;
		padding: 11px 10px 9px;
		color: $white;
		font-weight: $normal-base;
		font-size: 1.6rem;
		line-height: 2rem;
		text-align: center;
		margin-top: auto;
	}

	h2 {
		margin-bottom: 0;
		margin-top: 10px;
		text-align: left;
	}

	h4 {
		margin-bottom: 10px;
	}

	.content {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}
}

.detailsButton {
	margin-top: auto;
}

.pageButton {
	margin-top: auto;
	justify-content: center;

	div {
		justify-content: center !important;
	}
}

.addButton {
	width: 100%;
	transition: color 0.4s ease-in-out;
	display: block;
}

.content p {
	color: $grey;
	font-weight: $normal-base;
	font-size: 1.6rem;
	line-height: 2rem;
	transition: color 0.4s ease-in-out;
	margin-bottom: 0;
	text-align: left;
}
