@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

.modal-image {
	margin-bottom: 20px;

	.modalImageLeft {
		height: 30px;
		width: 30px;
		position: absolute;
		z-index: 3;
		left: 15px;
		background-repeat: no-repeat;
		background-position: center;
		top: 50%;
		margin-top: -15px;
		background-image: url(/images/icon-left.svg);
	}

	.modalImageRight {
		height: 30px;
		background-repeat: no-repeat;
		background-position: center;
		width: 30px;
		position: absolute;
		z-index: 3;
		right: 15px;
		top: 50%;
		margin-top: -15px;
		background-image: url(/images/icon-right.svg);
	}

	span {
		display: block;
		height: 200px;
		overflow: hidden;

		&.is-showing {
			z-index: 2;
		}

		img {
			width: 100%;
			margin-bottom: 0;
			max-width: 100%;
			max-height: unset;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

.nutritionTable {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 20px;
	font-size: 1.4rem;
	line-height: 1.6rem;
	gap: 10px;
}
.nutritionRow {
	flex-shrink: 0;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	height: 100%;

	& > div {
		height: 50%;
		flex-grow: 1;
		flex-shrink: 0;
		flex-basis: 50%;

		&:nth-child(1) {
			font-weight: bold;
		}
	}
}

.wrapper {
	text-align: left;

	h3 {
		margin-bottom: 10px;
	}

	p {
		font-weight: normal;
	}

	h5 {
		margin-bottom: 10px;
		font-size: 1.6rem;
		line-height: 1.8rem;
		margin-top: 20px;
	}

	& .small {
		font-size: 1.4rem;
		line-height: 1.6rem;
	}

	& .notice {
		font-size: 1.4rem;
		font-weight: $bold-base;
		line-height: 1.6rem;
		background: $light-grey;
		padding: 5px;
	}

	.accordionHeading {
		font-weight: $bold-base;
		font-size: 20px;
		line-height: 24px;
		color: $deep-green;
	}
}
