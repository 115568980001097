@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

.cards {
	display: flex;
	flex-direction: row;
	flex: 1;
	gap: 20px;
	max-width: 100%;
	overflow: hidden;
}

.videoContainer {
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 10px;
}

.leftIcon,
.rightIcon {
	width: 32px;
	flex-shrink: 0;
	flex-grow: 0;
	flex-basis: 32px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
}

.rightIcon {
	align-items: flex-end;
}

.background-deep-green {
	background-color: $deep-green;
	color: $white;

	p,
	h2,
	h3 {
		color: $white;
	}
}

.background-green {
	background-color: $light-green;
}

.background-purple {
	background-color: $purple;
	color: $white;

	p,
	h2,
	h3 {
		color: $white;
	}
}
