@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

.wrapper {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: #333;
	z-index: 9998;
}

.progress {
	position: absolute;
	bottom: 1px;
	left: 50%;
	transform: translateX(-50%);
	height: 6px;
	width: 100%;

	&::-webkit-progress-value {
		background: $white;
		border-radius: 6px;
	}

	&::-webkit-progress-bar {
		background: rgba(204, 204, 204, 0.5);
		border-radius: 6px;
	}
}

.videoWrapper {
	transform: translateY(-100vh);

	& .video {
		height: 100vh;
		color: white;
		font-size: 4rem;
		position: relative;
		display: flex;
		justify-content: center;

		.progressContainer {
			position: relative;
			max-width: 100%;
		}

		& video {
			top: 0;
			bottom: 0;
			left: 0;
			height: 100vh;
			object-fit: cover;

			&::-webkit-media-controls-start-playback-button {
				display: none;
			}
		}
	}
}

.directionControls {
	position: absolute;
	right: 24px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 9999;

	@include desktop() {
		right: 52px;
	}
}

.control {
	color: $white;
	background-color: rgba($grey, 0.75);
	border-radius: 100%;
	width: 32px;
	height: 32px;
	position: relative;
	cursor: pointer;
	z-index: 9999;

	@include tablet() {
		background-color: $grey;
	}

	&.close {
		position: absolute;
		top: 24px;
		right: 24px;

		@include desktop() {
			right: unset;
			left: 52px;
		}
	}

	&.share {
		position: absolute;
		top: 76px;
		right: 24px;

		@include desktop() {
			top: 24px;
			right: 52px;
		}
	}

	&.volume {
		position: absolute;
		top: 128px;
		right: 24px;

		@include desktop() {
			top: unset;
			bottom: 24px;
			right: 52px;
		}
	}

	& > * {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.video {
		position: absolute;
		top: 0;
		bottom: 0;
	}
}
